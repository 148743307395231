import React from 'react';

 /* Footer section */
function Footer(props) {
    return (
        <footer>
            <div className="container">
                <div className="d-flex justify-content-center align-items-center footer-section">
                    <div className="align-self-center">Scandiweb Test assignment</div>
                </div>
            </div>
        </footer>
    )
    
}

export default Footer;